// Field Success Message
//
// Markup:
// <div class="ffe-field-success-message">Dette gikk jo riktig så godt</div>
//
// Styleguide ffe-form.field-success-message

.ffe-field-success-message:extend(.ffe-field-info-message) {
    color: @ffe-farge-svart;

    .native & {
        @media (prefers-color-scheme: dark) {
            color: @ffe-farge-hvit;
        }
    }

    &[aria-hidden='true'] {
        display: none;
    }

    &::before:extend(.ffe-field-info-message::before) {
        content: url('data:image/svg+xml,%3Csvg width="11" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1.5 6l2.72 3.15a.5.5 0 00.804-.065L10 1" stroke="%23ffffff" stroke-width="1.75" stroke-linecap="round"/%3E%3C/svg%3E');
        background-color: @ffe-farge-nordlys-wcag;

        .native & {
            @media (prefers-color-scheme: dark) {
                content: url('data:image/svg+xml,%3Csvg width="11" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1.5 6l2.72 3.15a.5.5 0 00.804-.065L10 1" stroke="%23020A0A" stroke-width="1.75" stroke-linecap="round"/%3E%3C/svg%3E');
                background-color: @ffe-farge-nordlys;
            }
        }
    }
}
