// Radio button
//
// Markup:
// <fieldset class="ffe-fieldset ffe-input-group">
//     <legend class="ffe-form-label">Hva er din favorittfarge?</legend>
//     <input type="radio" aria-invalid="false" class="ffe-radio-input" id="base-radio-button-1"
//         value="red" name="favoriteColor">
//     <label for="base-radio-button-1" class="ffe-radio-button ffe-radio-button{{modifier_class}}">Rød</label>
//     <input type="radio" aria-invalid="false" class="ffe-radio-input" id="base-radio-button-2"
//         value="blue" name="favoriteColor">
//     <label for="base-radio-button-2" class="ffe-radio-button ffe-radio-button{{modifier_class}}">Blå</label>
//     <input type="radio" aria-invalid="false" class="ffe-radio-input" id="base-radio-button-3"
//         value="yellow" name="favoriteColor">
//     <label for="base-radio-button-3" class="ffe-radio-button ffe-radio-button{{modifier_class}}">Gul</label>
// </fieldset>
//
// --inline         - Inline
// --with-tooltip   - With tooltip
//
// Styleguide ffe-form.radio-button

.ffe-radio-button {
    display: block;
    position: relative;
    color: @ffe-farge-svart;
    cursor: pointer;
    margin: @ffe-spacing-xs 0;
    transition: width @ffe-transition-duration @ffe-ease-in-out-back;
    text-align: left;
    padding-left: @ffe-spacing-lg;
    padding-top: 1px;
    -webkit-tap-highlight-color: fade(@ffe-farge-vann, 15%);
    .native & {
        @media (prefers-color-scheme: dark) {
            color: @ffe-farge-hvit;
        }
    }

    &--inline {
        display: inline-block;
        margin-right: @ffe-spacing-md;
    }

    &--with-tooltip {
        display: inline-block;
        margin-right: @ffe-spacing-md;
        margin-bottom: 0;
    }

    &__tooltip-icon {
        display: inline-block;
        vertical-align: top;
        width: 25px;
        position: absolute;
        right: 0;
        margin-top: @ffe-spacing-xs;

        & .ffe-tooltip__icon {
            margin: 0;
        }
    }

    &__tooltip-text.ffe-tooltip__text {
        &:extend(.ffe-small-text);

        margin-left: @ffe-spacing-lg;
        margin-top: 0;
        margin-bottom: 0;
    }

    &__tooltip-text.ffe-tooltip__text--active {
        margin-top: @ffe-spacing-2xs;
    }

    &::before {
        content: '';
        background-color: @ffe-farge-hvit;
        border: 10px solid @ffe-farge-hvit;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 2px;
        transition: all @ffe-transition-duration @ffe-ease;
        .native & {
            @media (prefers-color-scheme: dark) {
                background-color: @ffe-farge-svart;
                border-color: @ffe-farge-svart;
            }
        }
    }

    &::after {
        content: '';
        border: 2px solid @ffe-farge-graa-wcag;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 2px;
        pointer-events: none;
        transition: all @ffe-transition-duration @ffe-ease;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-graa;
            }
        }
    }

    &--invalid {
        &::after {
            border-color: @ffe-farge-baer-wcag;
            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-farge-baer;
                }
            }
        }
    }
}

.ffe-radio-input {
    &:checked + .ffe-radio-button::before,
    &:hover + .ffe-radio-button::before,
    &:focus + .ffe-radio-button::before,
    &:active + .ffe-radio-button::before {
        outline: none;
    }

    &:checked + .ffe-radio-button::after,
    &:hover + .ffe-radio-button::after {
        border-color: @ffe-farge-vann;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-vann-70;
            }
        }
    }

    &:checked + .ffe-radio-button::before {
        background-color: @ffe-farge-vann;
        border: 5px solid @ffe-farge-hvit;
        .native & {
            @media (prefers-color-scheme: dark) {
                background-color: @ffe-farge-vann-30;
                border-color: @ffe-farge-svart;
            }
        }
    }

    &:focus + .ffe-radio-button::after {
        box-shadow: 0 0 0 3px @ffe-farge-hvit, 0 0 0 5px @ffe-farge-vann;

        .native & {
            @media (prefers-color-scheme: dark) {
                box-shadow: 0 0 0 3px @ffe-farge-svart,
                    0 0 0 5px @ffe-farge-hvit;
            }
        }
    }

    &:focus:not(:focus-visible) + .ffe-radio-button::after {
        box-shadow: none;
    }

    &:hover + .ffe-radio-button--invalid::after {
        border-color: @ffe-farge-baer-wcag;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-baer;
            }
        }
    }

    &:checked + .ffe-radio-button--invalid::after {
        border-color: @ffe-farge-baer-wcag;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-baer;
            }
        }
    }
}
