// Sets default body color and background color for light and dark themes
.ffe-body {
    color: @ffe-farge-svart;
    background-color: @ffe-farge-hvit;
    &.native {
        @media (prefers-color-scheme: dark) {
            color: @ffe-farge-lysgraa;
            background-color: @ffe-farge-svart;
        }
    }
}
