@import 'breakpoints';
@import 'dimensions';

.ffe-icon-base {
    background-size: cover;
    display: inline-block;
    height: 50px;
    vertical-align: middle;
    width: 50px;

    @media (min-width: @breakpoint-sm) {
        height: 75px;
        width: 75px;
    }

    @media (min-width: @breakpoint-md) {
        height: 90px;
        width: 90px;
    }
}
