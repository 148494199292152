// Field Error Message
//
// Markup:
// <div class="ffe-field-error-message">Noe er feil her</div>
//
// Styleguide ffe-form.field-error-message

.ffe-field-error-message:extend(.ffe-field-info-message) {
    color: @ffe-farge-svart;
    .native & {
        @media (prefers-color-scheme: dark) {
            color: @ffe-farge-hvit;
        }
    }

    &[aria-hidden='true'] {
        display: none;
    }

    &::before:extend(.ffe-field-info-message::before) {
        content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" height="12" width="12" x="1650" y="1200" viewBox="0 0 200 200"%3E%3Cpath d="M99.882.64a16.562 16.562 0 0 0-16.294 16.772v93.863a16.562 16.562 0 1 0 33.102 0V17.412A16.562 16.562 0 0 0 99.882.64zm.257 162.054c-9.145 0-16.55 7.405-16.55 16.55 0 9.147 7.405 16.589 16.55 16.589 9.146 0 16.551-7.442 16.551-16.588s-7.405-16.551-16.55-16.551z"/%3E%3C/svg%3E');
        background-color: @ffe-farge-baer-wcag;
        .native & {
            @media (prefers-color-scheme: dark) {
                content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23002776" height="12" width="12" x="1650" y="1200" viewBox="0 0 200 200"%3E%3Cpath d="M99.882.64a16.562 16.562 0 0 0-16.294 16.772v93.863a16.562 16.562 0 1 0 33.102 0V17.412A16.562 16.562 0 0 0 99.882.64zm.257 162.054c-9.145 0-16.55 7.405-16.55 16.55 0 9.147 7.405 16.589 16.55 16.589 9.146 0 16.551-7.442 16.551-16.588s-7.405-16.551-16.55-16.551z"/%3E%3C/svg%3E');
                background-color: @ffe-farge-baer;
            }
        }
    }
}
