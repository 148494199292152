// Phone number
//
// Markup:
// <div class="ffe-phone-number">
//     <div class="ffe-phone-number__country-code">
//         <label class="ffe-form-label" for="phone-countrycode">Landskode</label>
//         <div class="ffe-phone-number__input-group">
//             <span class="ffe-phone-number__plus">+</span>
//             <input type="tel" id="phone-countrycode" class="ffe-input-field ffe-phone-number__country-code-input"
//                 value="47">
//         </div>
//     </div>
//     <div class="ffe-phone-number__number">
//         <label class="ffe-form-label" for="phone-number">Telefonnummer</label>
//         <input type="tel" id="phone-number" class="ffe-input-field ffe-phone-number__phone-input"
//             value="123123123">
//     </div>
// </div>
//
// Styleguide ffe-form.phone-number

.ffe-phone-number {
    &__input-group {
        display: flex;
    }

    &__country-code {
        min-width: 110px;
        flex-grow: 1;

        label {
            white-space: nowrap;
            margin-right: @ffe-spacing-xs;
        }
    }

    &__country-code-input {
        max-width: 60px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-right: @ffe-spacing-sm;
    }

    &__plus {
        font-weight: 400;
        line-height: 1;
        padding: 12px @ffe-spacing-xs;
        background-color: @ffe-farge-hvit;
        border: 2px solid @ffe-farge-graa-wcag;
        border-radius: 4px 0 0 4px;
        border-right: 0;
        transition: all @ffe-transition-duration @ffe-ease;
        .native & {
            @media (prefers-color-scheme: dark) {
                background-color: @ffe-farge-svart;
                border-color: @ffe-farge-graa;
            }
        }
    }

    &__number {
        flex-grow: 10000; // Make this element fill all remaining white space

        label {
            white-space: nowrap;
        }
    }

    &__phone-input:disabled,
    &__country-code-input:disabled {
        color: transparent;
    }
}
