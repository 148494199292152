@import "ffe";

.ffe-searchable-dropdown {
    position: relative;
    @button-width: 40px;

    .ffe-input-field {
        padding-right: calc(@button-width + 3px);
    }

    &__list {
        display: none;
        background: @ffe-farge-hvit;
        position: absolute;
        width: 100%;
        z-index: 100000;
        border-radius: 2px;
        height: 0;
        overflow: hidden;

        &--open {
            display: block;
            height: auto;
            border: 1px solid @ffe-farge-graa-wcag;
        }
    }

    &__no-match {
        padding: @ffe-spacing @ffe-spacing-sm;

        & + .ffe-searchable-dropdown__list-item-container {
            border-top: 1px solid @ffe-farge-graa-wcag;
        }

        .ffe-body-paragraph {
            margin: 0;
        }
    }

    &__list-item-container:not(:last-child) {
        border-bottom: 1px solid @ffe-farge-graa-wcag;
    }

    &__list-item-body {
        padding: @ffe-spacing @ffe-spacing-sm;
        cursor: pointer;

        &:hover,
        &--highlighted,
        &--highlighted:hover {
            background: @ffe-farge-vann;
            color: @ffe-farge-hvit;
        }
    }

    &__list-item-body-details {
        display: flex;
        justify-content: space-between;
    }

    &__button {
        position: absolute;
        background: transparent;
        height: 100%;
        top: 0;
        right: 0;
        border: 2px solid transparent;
        width: @button-width;
        padding: 0;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        transition: all @ffe-transition-duration @ffe-ease;

        &--hidden {
            display: none;
        }

        &:hover {
            border-color: @ffe-farge-vann;
        }

        &:focus {
            border: 2px solid @ffe-farge-vann;
        }

        .ffe-input-field:focus ~ &:hover {
            border-color: transparent;
            box-shadow: 0 0 0 2px @ffe-farge-vann;
        }

        svg {
            display: block;
            transition: transform @ffe-transition-duration @ffe-ease-in-out-back;
        }

        &--flip {
            svg {
                transform: rotateZ(180deg);
            }
        }

        &--arrow {
            svg {
                fill: @ffe-farge-vann;
                height: 17px;
                width: 17px;
                transition: fill @ffe-transition-duration @ffe-ease;
            }

            &:hover {
                svg {
                    fill: @ffe-farge-fjell;
                }
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    .ffe-searchable-dropdown {
        &__list {
            background: @ffe-black-darkmode;
            color: @ffe-white-darkmode;
        }

        &__list-item-body {
            color: @ffe-white-darkmode;
        }

        &__list-item-body-details {
            .ffe-micro-text {
                color: @ffe-grey-cloud-darkmode;
            }
        }

        &__button {
            &:hover,
            &:focus {
                border-color: @ffe-farge-vann-70;
            }

            &--arrow {
                svg {
                    fill: @ffe-farge-vann-70;
                }

                &:hover {
                    svg {
                        fill: @ffe-farge-vann-30;
                    }
                }
            }
        }
    }
}
