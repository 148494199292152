.ffe-header {
    font-family: sans-serif;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    display: block;
}
.ffe-header__secondary-nav,
.ffe-header__user-nav,
.ffe-header__site-nav {
    display: block;
}
.ffe-header__link {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
.ffe-header__link:active,
.ffe-header__link:hover {
    outline-width: 0;
}
.ffe-header__svg-icon {
    overflow: hidden;
}
.ffe-header__icon-button {
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
}
.ffe-header__icon-button::-moz-focus-inner {
    border-style: none;
    padding: 0;
}
.ffe-header,
.ffe-header *,
.ffe-header *::before,
.ffe-header *::after {
    box-sizing: border-box;
}
.ffe-header {
    color: #020a0a;font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;line-height: 1.5rem;font-size: 1rem;
}
@media (min-width: 480px) {
    .ffe-header {
        line-height: 1.5rem;
    }
}
.ffe-header__logout-button {
    align-items: center;-webkit-appearance: none;-moz-appearance: none;appearance: none;border: 2px solid transparent;border-radius: 6em;color: #fff;cursor: pointer;display: flex;font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;justify-content: center;line-height: 24px;overflow: hidden;padding: 8px 24px;position: relative;text-align: center;text-decoration: none;transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;white-space: nowrap;width: 100%;font-size: 1rem;
}
.ffe-header__logout-button::after {
    content: '';opacity: 0;display: block;position: absolute;background: transparent;border-radius: 1.5rem;top: -5px;left: -5px;right: -5px;bottom: -5px;box-shadow: 0 0 0 2px #005aa4;transition: opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.ffe-header__logout-button:focus::after {
    opacity: 1;
}
.ffe-header__logout-button:focus:not(:focus-visible)::after {
    opacity: 0;
}
.ffe-header__logout-button:focus {
    outline: none;position: relative;overflow: unset;
}
.ffe-header__logout-button:active {
    -webkit-transform: scale(0.97);
    transform: scale(0.97);
}
@media (min-width: 480px) {
    .ffe-header__logout-button {
        display: inline-flex;width: auto;padding: 8px 32px;
    }
}.ffe-header__logout-button {
     background-color: #fff;border: solid 2px #005aa4;color: #005aa4;
 }
.ffe-header__logout-button:focus,
.ffe-header__logout-button:active {
    color: #005aa4;
}
.ffe-header__logout-button:hover {
    background-color: #e1ebf4;border-color: #002776;color: #002776;
}
.ffe-header__logout-button {
    padding: 4px 16px;font-size: 0.875rem;
}
.ffe-header__logout-button--loading {
    pointer-events: none;
}
.ffe-header__logout-button-label {
    align-items: center;display: flex;transition: -webkit-transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.ffe-header__logout-button--loading .ffe-header__logout-button-label {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px);opacity: 0;
}
.ffe-header__logout-button-spinner {
    bottom: 0;left: 0;opacity: 0;position: absolute;right: 0;top: 8px;-webkit-transform: translateY(24px);transform: translateY(24px);transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);visibility: hidden;
}
.ffe-header__logout-button--loading .ffe-header__logout-button-spinner {
    opacity: 1;-webkit-transform: none;transform: none;visibility: visible;
}
.ffe-header__logout-button-spinner {
    top: 6px;
}
.ffe-header__logout-button-spinner::after {
    -webkit-animation: 1s linear infinite button-loading-spin;
    animation: 1s linear infinite button-loading-spin;border: 3px solid currentColor;border-radius: 50%;border-top-color: transparent;content: '';display: block;height: 22px;margin: 0 auto;width: 22px;
}
.ffe-header__icon-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;background-color: transparent;border: none;border-radius: 1.375rem;cursor: pointer;display: inline-flex;font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;margin: 0 -4px;transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);overflow: hidden;padding: 0 4px;text-decoration: none;font-size: 1rem;
}
.ffe-header__icon-button:active {
    -webkit-transform: scale(0.97);
    transform: scale(0.97);
}
.ffe-header__icon-button:focus {
    box-shadow: 0 0 0 2px #005aa4;outline: none;
}
.ffe-header__icon-button {
    align-content: center;color: #005aa4;flex-wrap: wrap;padding: 8px 17px;border: 2px solid transparent;
}
.ffe-header__icon-button::after {
    border-bottom: solid 1px currentColor;content: ' ';display: block;width: 100%;
}
.ffe-header__icon-button:hover {
    color: #002776;
}
.ffe-header {
    background: #fff;
    text-align: center;
    z-index: 999;
}
.ffe-header__user-nav-list--visible,
.ffe-header__site-nav-list--visible {
    z-index: 100000;
}
.ffe-header__border {
    background: #fff;
    border-bottom: 1px solid #d8d8d8;
}
.ffe-header__border:first-child {
    position: relative;
    z-index: 1000;
}
.ffe-header__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 auto;
    padding: 0 16px;
    max-width: 1280px;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ffe-header__wrapper > .ffe-header__secondary-nav,
    .ffe-header__wrapper > .ffe-header__logo,
    .ffe-header__wrapper > .ffe-header__icon-button--user-nav,
    .ffe-header__wrapper > .ffe-header__site-nav {
        /* \9 is a hack to have the value only apply to IE9 */
        display: block\9;
    }
}
.ffe-header__link {
    transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.ffe-header__link:link,
.ffe-header__link:visited,
.ffe-header__link:active {
    color: #020a0a;
    text-decoration: none;
}
.ffe-header__link:hover {
    color: #005aa4;
}
.ffe-header__link--active:link,
.ffe-header__link--active:visited {
    position: relative;
    color: #002776;
}
.ffe-header__link--active::after {
    content: '';
    display: inline-block;
    border-bottom: 2px solid #002776;
    position: absolute;
    width: 100%;
    bottom: -2px;
    left: 0;
}
.ffe-header__link--active:focus::after {
    border-color: #fff;
}
.ffe-header__link:focus {
    border-radius: 1px;
    background-color: #005aa4;
    box-shadow: 0 0 0 4px #005aa4;
    color: #fff;
    outline: none;
}
.ffe-header__user-nav .ffe-header__link:focus {
    box-shadow: none;
}
.ffe-header__link:focus:not(:focus-visible) {
    background-color: transparent;
    box-shadow: none;
    color: #002776;
}
.ffe-header__link--disabled {
    color: #676767;
    cursor: default;
}
.ffe-header__link .ffe-header__notification-bubble {
    margin-left: 4px;
}
.ffe-header__list {
    margin: 0;
    padding: 0;
    text-align: left;
    list-style: none;
}
.ffe-header__list-item--centered {
    text-align: center;
}
.ffe-header__logo {
    flex-grow: 1;
    margin: 8px 0;
}
.ffe-header__logo-svg {
    height: 30px;
    vertical-align: middle;
    border: none;
}
.ffe-header__icon-button {
    color: #002776;
    /* stylelint-disable declaration-block-no-duplicate-properties */
    /* stylelint-enable declaration-block-no-duplicate-properties */
}
.ffe-header__icon-button::after {
    border: none;
}
.ffe-header__icon-button--user-nav {
    position: relative;
    overflow: inherit;
}
.ffe-header__icon-button--user-nav .ffe-header__notification-bubble {
    position: absolute;
    left: 18px;
    bottom: -4px;
}
.ffe-header__logout-button {
    margin: 8px 0;
    border-radius: 20px;
}
.ffe-header__logout-button-spinner::after {
    -webkit-animation-name: logout-button-loading-spin;
    animation-name: logout-button-loading-spin;
}
@-webkit-keyframes logout-button-loading-spin {
    from {
        -webkit-transform: none;
        transform: none;
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes logout-button-loading-spin {
    from {
        -webkit-transform: none;
        transform: none;
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.ffe-header__user-icon,
.ffe-header__user-chevron-icon,
.ffe-header__site-nav-icon {
    fill: #002776;
}
.ffe-header__user-icon,
.ffe-header__site-nav-icon {
    height: 25px;
    width: 25px;
    vertical-align: middle;
}
.ffe-header__user-chevron-icon {
    height: 14px;
    width: 14px;
    vertical-align: middle;
    margin-left: 4px;
    transition: -webkit-transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38);
    transition: transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38);
    transition: transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38), -webkit-transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38);
}
.ffe-header__user-chevron--expanded .ffe-header__user-chevron-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.ffe-header__user-nav-list {
    transition: opacity 0.1s, -webkit-transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.1s;
    transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.1s, -webkit-transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    overflow: hidden;
}
.ffe-header__user-nav-list:not(.ffe-header__user-nav-list--visible) {
    visibility: hidden;
    height: 1px;
    overflow: hidden;
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
    opacity: 0;
}
.ffe-header__user-nav-list .ffe-header__link {
    display: block;
    padding: 8px 8px;
}
.ffe-header__site-nav-hamburger {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    height: 25px;
    width: 25px;
}
.ffe-header__site-nav-hamburger__bar,
.ffe-header__site-nav-hamburger::before,
.ffe-header__site-nav-hamburger::after {
    display: inline-block;
    background: #002776;
    width: 25px;
    height: 4px;
    border-radius: 2px;
}
.ffe-header__site-nav-hamburger__bar {
    position: absolute;
    left: 0;
    bottom: 10px;
    transition: width 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38), -webkit-transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38);
    transition: transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38), width 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38);
    transition: transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38), width 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38), -webkit-transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38);
}
.ffe-header__site-nav-hamburger::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 18px;
}
.ffe-header__site-nav-hamburger::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 2px;
}
.ffe-header__site-nav-hamburger::before,
.ffe-header__site-nav-hamburger::after {
    transition: -webkit-transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38);
    transition: transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38);
    transition: transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38), -webkit-transform 0.2s cubic-bezier(0.46, -0.265, 0.48, 1.38);
}
.ffe-header__site-nav-hamburger--expanded .ffe-header__site-nav-hamburger__bar {
    width: 0;
    -webkit-transform: translate(12px);
    transform: translate(12px);
}
.ffe-header__site-nav-hamburger--expanded::before {
    -webkit-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
}
.ffe-header__site-nav-hamburger--expanded::after {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
}
.ffe-header__secondary-nav {
    display: none;
    text-transform: uppercase;
}
.ffe-header__notification-bubble {
    font-family: 'SpareBank1-medium', 'MuseoSansRounded-700', arial, sans-serif;
    font-weight: normal;
    font-size: 0.75rem;
    display: inline-block;
    min-width: 19px;
    height: 19px;
    padding: 0 4px;
    line-height: 1.4rem;
    background: #247a5d;
    color: #fff;
    border-radius: 10px;
    text-align: center;
}
@media (min-width: 768px) {
    .ffe-header__icon-button--site-nav,
    .ffe-header__user-icon {
        display: none;
    }
    .ffe-header__logo a {
        display: inline-block;
        height: 60px;
        line-height: 3.75rem;
        transition: box-shadow 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    }
    .ffe-header__logo a:focus {
        outline: none;
        box-shadow: 0 0 0 2px #005aa4;
        border-radius: 1px;
    }
    .ffe-header__logo-svg {
        height: 40px;
    }
    .ffe-header__site-nav {
        width: 100%;
        flex-basis: 100%;
        padding: 8px;
    }
    .ffe-header__site-nav .ffe-header__list-item {
        display: inline-block;
        margin: 0 8px;
    }
    .ffe-header__site-nav-list {
        text-align: center;
    }
    .ffe-header__secondary-nav {
        display: block;
        flex-basis: 30%;
    }
    .ffe-header__secondary-nav .ffe-header__list-item {
        display: inline-block;
        margin: 0 16px 0 0;
    }
    .ffe-header__user-nav-toggle {
        flex-basis: 30%;
        text-align: right;
    }
    .ffe-header__user-nav .ffe-header__link:hover {
        background: #e1ebf4;
    }
    .ffe-header__user-nav-list {
        position: absolute;
        right: 20px;
        top: -16px;
        min-width: 225px;
        background: #fff;
        border: 2px solid #005aa4;
    }
    .ffe-header__logout-button {
        margin: 8px 8px;
    }
    .ffe-header__icon-button--user-nav .ffe-header__notification-bubble {
        left: -8px;
        bottom: 12px;
    }
}
@media (max-width: 767px) {
    .ffe-header__border:last-child {
        margin-top: -1px;
    }
    .ffe-header__user-name {
        display: none;
    }
    .ffe-header__user-nav {
        margin-right: 16px;
    }
    .ffe-header__user-nav-toggle {
        order: -1;
    }
    .ffe-header__user-nav-list,
    .ffe-header__site-nav-list {
        visibility: hidden;
        height: 1px;
        position: absolute;
        z-index: 999;
        right: 0;
        left: 0;
        top: 1px;
        padding: 16px 0;
        border-bottom: 1px solid #adadad;
        -webkit-transform: translateY(-25px);
        transform: translateY(-25px);
        transition: -webkit-transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
        transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
        transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    }
    .ffe-header__user-nav-list .ffe-header__list-item,
    .ffe-header__site-nav-list .ffe-header__list-item {
        padding: 8px 16px;
    }
    .ffe-header__user-nav-list .ffe-header__link,
    .ffe-header__site-nav-list .ffe-header__link {
        display: block;
        padding: 4px 0;
    }
    .ffe-header__user-nav-list--visible,
    .ffe-header__site-nav-list--visible {
        visibility: visible;
        height: auto;
        width: 100%;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        background-color: #fff;
    }
}
@media print {
    .ffe-header__logo {
        text-align: left;
    }
    .ffe-header__user-nav,
    .ffe-header__user-icon,
    .ffe-header__user-chevron,
    .ffe-header__site-nav,
    .ffe-header__site-nav-toggle,
    .ffe-header__notification-bubble {
        display: none;
    }
}
