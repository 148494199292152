// Radio block
//
// Markup:
// <fieldset class="ffe-fieldset ffe-input-group">
//     <legend class="ffe-form-label">Hvem eier bilen du skal forsikre?</legend>
//     <div class="ffe-radio-block">
//         <input type="radio" class="ffe-radio-input" id="radio-block-1" name="owner" value="you"
//             aria-invalid="false">
//         <div class="ffe-radio-block__content">
//             <label class="ffe-radio-block__header" for="radio-block-1">Du</label>
//             <div class="ffe-radio-block__wrapper ffe-radio-block__wrapper--empty"></div>
//         </div>
//     </div>
//     <div class="ffe-radio-block">
//         <input type="radio" class="ffe-radio-input" id="radio-block-2" name="owner" value="partner"
//             aria-invalid="false">
//         <div class="ffe-radio-block__content">
//             <label class="ffe-radio-block__header" for="radio-block-2">Ektefelle, samboer eller registrert partner</label>
//         </div>
//     </div>
//     <div class="ffe-radio-block">
//         <input type="radio" class="ffe-radio-input" id="radio-block-3" name="owner" value="leasing-company"
//             aria-invalid="false">
//         <div class="ffe-radio-block__content">
//             <label class="ffe-radio-block__header" for="radio-block-3">Leasingselskap</label>
//         </div>
//     </div>
// </fieldset>
//
// Styleguide ffe-form.radio-block

.ffe-radio-block {
    margin-top: @ffe-spacing-md;
    width: 100%;
    transition: width @ffe-transition-duration @ffe-ease-in-out-back;

    &__content {
        width: 100%;
        position: relative;
        border: 2px solid @ffe-farge-graa-wcag;
        border-radius: 5px;
        display: inline-block;
        margin-right: @ffe-spacing-xs;
        color: @ffe-black;
        background-color: @ffe-farge-hvit;
        cursor: pointer;
        transition: all @ffe-transition-duration @ffe-ease;

        .native & {
            @media (prefers-color-scheme: dark) {
                border: 2px solid @ffe-farge-graa;
                color: @ffe-farge-hvit;
                background: @ffe-farge-svart;
            }
        }

        &::before {
            .ffe-sb1-radioblob();

            left: 20px;
            top: 10px;
            z-index: 1;
        }

        .native &::before {
            @media (prefers-color-scheme: dark) {
                background: none;
                border-color: @ffe-white-darkmode;
            }
        }
    }

    &__header {
        display: block;
        padding: @ffe-spacing-xs @ffe-spacing-3xl @ffe-spacing-xs;
        cursor: pointer;
        border-bottom: thin solid @ffe-farge-graa-wcag;
        transition: background-color @ffe-transition-duration @ffe-ease,
            border-color @ffe-transition-duration @ffe-ease;

        .native & {
            @media (prefers-color-scheme: dark) {
                border-bottom: thin solid @ffe-farge-graa;
            }
        }
    }

    &__wrapper {
        padding: @ffe-spacing-sm @ffe-spacing-sm @ffe-spacing-lg;
        cursor: auto;

        &--empty {
            padding: 0;
        }

        &[aria-hidden='true'] {
            display: none;
        }
    }

    &:hover {
        .ffe-radio-block__content {
            border-color: @ffe-farge-fjell;

            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-farge-vann-70;
                }
            }
        }

        .ffe-radio-block__header {
            border-color: @ffe-farge-fjell;

            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-farge-vann-70;
                }
            }
        }
    }

    .ffe-fieldset--error & {
        .ffe-radio-block__content {
            border-color: @ffe-farge-baer-wcag;

            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-farge-baer;
                }
            }
        }

        .ffe-radio-block__header {
            border-color: @ffe-farge-baer-wcag;

            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-farge-baer;
                }
            }
        }
    }
}

// Weakness: Requires specific dom structure to work. Rewrite?
.ffe-radio-input {
    &,
    + .ffe-radio-block__content[role='presentation'],
    > .ffe-radio-block__header {
        border-bottom: none;
    }

    &:checked + .ffe-radio-block__content {
        border: 2px solid @ffe-farge-fjell;

        > .ffe-radio-block__header {
            border-bottom: thin solid @ffe-farge-fjell;
            background-color: @ffe-farge-fjell;
            color: @ffe-farge-hvit;
        }
    }

    .native &:checked + .ffe-radio-block__content {
        @media (prefers-color-scheme: dark) {
            border: 2px solid @ffe-farge-vann-70;

            > .ffe-radio-block__header {
                border-bottom: thin solid @ffe-farge-vann-70;
                background-color: @ffe-farge-vann-70;
                color: @ffe-farge-svart;
            }
        }
    }

    &:checked + .ffe-radio-block__content::before {
        .ffe-sb1-radioblob-active();

        .native & {
            @media (prefers-color-scheme: dark) {
                border: 5px solid @ffe-white-darkmode;
                background: @ffe-blue-azure;
            }
        }
    }

    &:focus + .ffe-radio-block__content {
        box-shadow: 0 0 0 3px @ffe-farge-hvit, 0 0 0 5px @ffe-farge-vann;

        .native & {
            @media (prefers-color-scheme: dark) {
                box-shadow: 0 0 0 3px @ffe-farge-svart,
                    0 0 0 5px @ffe-farge-hvit;
            }
        }
    }
}

@media (min-width: @breakpoint-sm) {
    .ffe-radio-block__wrapper {
        padding-left: @ffe-spacing-3xl;
    }
}
