// Dropdown
//
// Markup:
// <div>
//     <label class="ffe-form-label" for="dropdown-example">Måned</label>
//     <select class="ffe-dropdown" id="dropdown-example">
//         <option value="jan">Januar</option>
//         <option value="feb">Februar</option>
//         <option value="mar">Mars</option>
//     </select>
// </div>
//
// Styleguide ffe-form.dropdown

.ffe-dropdown {
    appearance: none;
    background-color: @ffe-farge-hvit;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: ~'calc(100% - 9px) 50%';
    border: 2px solid @ffe-farge-graa-wcag;
    border-radius: 4px;
    color: @ffe-farge-svart;
    display: block;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%201000%201000%22%20width%3D%2218%22%20height%3D%2218%22%20xml%3Aspace%3D%22preserve%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23005aa4%22%20d%3D%22M111.1%20228.6c19.2%200%2038.4%207.3%2053%2022l336.7%20336.7%20334.5-334.5c29.3-29.3%2076.8-29.3%20106.1%200%2029.3%2029.3%2029.3%2076.8%200%20106.1L553.8%20746.3c-29.3%2029.3-76.8%2029.3-106.1%200L58.1%20356.6c-29.3-29.3-29.3-76.8%200-106.1C72.7%20235.9%2091.9%20228.6%20111.1%20228.6z%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');

    .native & {
        @media (prefers-color-scheme: dark) {
            background-color: @ffe-farge-svart;
            border-color: @ffe-farge-graa;
            color: @ffe-farge-hvit;
            box-shadow: none;
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%201000%201000%22%20width%3D%2218%22%20height%3D%2218%22%20xml%3Aspace%3D%22preserve%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%234d8cbf%22%20d%3D%22M111.1%20228.6c19.2%200%2038.4%207.3%2053%2022l336.7%20336.7%20334.5-334.5c29.3-29.3%2076.8-29.3%20106.1%200%2029.3%2029.3%2029.3%2076.8%200%20106.1L553.8%20746.3c-29.3%2029.3-76.8%2029.3-106.1%200L58.1%20356.6c-29.3-29.3-29.3-76.8%200-106.1C72.7%20235.9%2091.9%20228.6%20111.1%20228.6z%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
        }
    }

    font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
    height: 45px;
    padding: 0 @ffe-spacing-lg 0 @ffe-spacing-sm;
    line-height: 20px;
    transition: all @ffe-transition-duration @ffe-ease;
    width: 100%;
    .ffe-fontsize-form-dropdown();

    &:hover {
        border-color: @ffe-farge-vann;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-vann-70;
            }
        }
    }

    &:focus,
    &:active {
        border-color: @ffe-farge-hvit;
        box-shadow: 0 0 0 2px @ffe-farge-vann;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%201000%201000%22%20width%3D%2218%22%20height%3D%2218%22%20xml%3Aspace%3D%22preserve%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23002776%22%20d%3D%22M111.1%20228.6c19.2%200%2038.4%207.3%2053%2022l336.7%20336.7%20334.5-334.5c29.3-29.3%2076.8-29.3%20106.1%200%2029.3%2029.3%2029.3%2076.8%200%20106.1L553.8%20746.3c-29.3%2029.3-76.8%2029.3-106.1%200L58.1%20356.6c-29.3-29.3-29.3-76.8%200-106.1C72.7%20235.9%2091.9%20228.6%20111.1%20228.6z%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
        outline: none;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-svart;
                box-shadow: 0 0 0 2px @ffe-farge-vann-70;
                background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%201000%201000%22%20width%3D%2218%22%20height%3D%2218%22%20xml%3Aspace%3D%22preserve%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M111.1%20228.6c19.2%200%2038.4%207.3%2053%2022l336.7%20336.7%20334.5-334.5c29.3-29.3%2076.8-29.3%20106.1%200%2029.3%2029.3%2029.3%2076.8%200%20106.1L553.8%20746.3c-29.3%2029.3-76.8%2029.3-106.1%200L58.1%20356.6c-29.3-29.3-29.3-76.8%200-106.1C72.7%20235.9%2091.9%20228.6%20111.1%20228.6z%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
            }
        }
    }

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 @ffe-black;
        transition: color 0ms;
    }

    &:focus::-ms-value {
        background: @ffe-farge-hvit;
        color: @ffe-farge-svart;
        .native & {
            @media (prefers-color-scheme: dark) {
                background: @ffe-grey-darkmode;
                color: @ffe-white-darkmode;
            }
        }
    }

    &::-ms-expand {
        display: none;
    }

    &::-ms-clear {
        display: none;
    }

    &--inline {
        display: inline-block;
        width: auto;
    }

    &::placeholder {
        color: @ffe-farge-moerkgraa;
        opacity: 1;
        .native & {
            @media (prefers-color-scheme: dark) {
                color: @ffe-grey-darkmode;
            }
        }
    }
}
