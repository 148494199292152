.ffe-image-card {
    max-width: 290px;
    padding: 0;
    border: 0;
    display: flex;
    flex-flow: column nowrap;

    &:focus,
    &:active,
    &:hover {
        .ffe-image-card__image__overlay,
        .ffe-image-card__body {
            border-color: @ffe-farge-vann;
        }
    }

    &__image {
        max-height: 160px;
        position: relative;
        overflow: hidden;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &__image__overlay,
    &__body {
        width: 100%;
        overflow: hidden;
        border: 2px solid transparent;
        transition: border-color @ffe-transition-duration;
    }

    &__image__overlay {
        z-index: 10;
        height: 100%;
        border-bottom: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        position: absolute;
    }

    &__body {
        text-align: center;
        padding: @ffe-spacing-sm @ffe-spacing-sm @ffe-spacing-xs @ffe-spacing-sm;
        border-top: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        min-height: 200px;
    }

    @media (min-width: @breakpoint-md) {
        .ffe-image-card__icon {
            margin: 0 @ffe-spacing-md 0;
        }
    }

    @media (min-width: @breakpoint-lg) {
        .ffe-image-card__icon {
            margin: 0 @ffe-spacing-lg 0 @ffe-spacing-xs;
        }
    }
}
