// Input field
//
// Markup:
// <div>
//     <label class="ffe-form-label" for="input-first-name">Fornavn</label>
//     <input class="ffe-input-field ffe-input-field{{modifier_class}}" id="input-first-name">
// </div>
//
// --inline      - Inline
// --condensed   - Condensed
// --text-like   - Text-like styling
//
// Styleguide ffe-form.input-field

.ffe-input-field {
    display: block;
    height: 45px;
    padding: 0 @ffe-spacing-sm;
    font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
    color: @ffe-farge-svart;
    border-radius: 4px;
    border: 2px solid @ffe-farge-graa-wcag;
    transition: all @ffe-transition-duration @ffe-ease;
    width: 100%;
    .ffe-fontsize-form-input();
    .native & {
        @media (prefers-color-scheme: dark) {
            background-color: @ffe-farge-svart;
            color: @ffe-farge-hvit;
            border-color: @ffe-farge-graa;
            box-shadow: none;
        }
    }

    &:hover {
        border-color: @ffe-farge-vann;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-vann-70;
            }
        }
    }

    &:focus,
    &:active {
        border-color: @ffe-farge-hvit;
        box-shadow: 0 0 0 2px @ffe-farge-vann;
        outline: none;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-svart;
                box-shadow: 0 0 0 2px @ffe-farge-vann-70;
            }
        }
    }

    &--inline {
        display: inline-block;
        width: auto;
    }
    &--text-right-align {
        text-align: right;
    }

    &--condensed {
        height: 38px;
    }

    &--text-like {
        background-color: @ffe-farge-hvit;
        border: none;
        border-bottom: 2px solid @ffe-farge-graa-wcag;
        border-radius: 4px 4px 0 0;
        box-shadow: none;
        color: @ffe-farge-svart;
        display: inline-block;
        height: 2em;
        transition: all @ffe-transition-duration @ffe-ease;
        .native & {
            @media (prefers-color-scheme: dark) {
                color: @ffe-farge-hvit;
                background-color: transparent;
                border-color: @ffe-farge-graa;
                box-shadow: none !important;
            }
        }

        &:focus {
            box-shadow: none;
            border-color: @ffe-farge-vann;
            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-farge-vann-70;
                }
            }
        }
    }

    &::-ms-clear {
        display: none;
    }

    &::placeholder {
        color: @ffe-farge-moerkgraa;
        opacity: 1;
        .native & {
            @media (prefers-color-scheme: dark) {
                color: @ffe-grey-silver-darkmode;
            }
        }
    }
}
