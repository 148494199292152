// Fieldset
//
// Markup:
// <fieldset class="ffe-fieldset">Fieldset</fieldset>
//
// Styleguide ffe-form.fieldset

.ffe-fieldset {
    border: 0;
    padding: 0;
    margin: 0;

    > *:nth-last-child(1) {
        margin-bottom: @ffe-spacing-lg;
    }

    &--error {
        > *:nth-last-child(1) {
            margin-bottom: 0;
        }

        > *:nth-last-child(2) {
            margin-bottom: @ffe-spacing-lg;
        }
    }

    .ffe-field-error-message {
        margin-top: -24px;
        margin-bottom: 0;
    }

    &--no-extra-margin {
        > *:nth-last-child(1) {
            margin-bottom: initial;
        }

        > *:nth-last-child(2) {
            margin-bottom: initial;
        }

        .ffe-field-error-message {
            margin: @ffe-spacing-2xs 0 @ffe-spacing-xs;
        }
    }
}
