/*
    Radioblob: Small design element on "radio button"-like elements
*/
.ffe-sb1-radioblob() {
    content: '';
    background-color: @ffe-farge-hvit;
    border: 2px solid @ffe-farge-graa-wcag;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
    .native & {
        @media (prefers-color-scheme: dark) {
            background-color: @ffe-farge-svart !important;
            border-color: @ffe-farge-graa !important;
        }
    }
}

/*
    Radioblob: Active state of the blob
*/
.ffe-sb1-radioblob-active() {
    background-color: @ffe-farge-vann;
    border: 5px solid @ffe-farge-hvit;
    .native & {
        @media (prefers-color-scheme: dark) {
            background-color: @ffe-farge-vann-70 !important;
            border-color: @ffe-farge-svart !important;
        }
    }
}
