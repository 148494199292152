// Textarea
//
// Markup:
// <div>
//     <label class="ffe-form-label" for="textarea-example">Beskriv skaden</label>
//     <textarea class="ffe-textarea" id="textarea-example" rows="8">Dette er et tekstfelt for lengre tekster.</textarea>
// </div>
//
// Styleguide ffe-form.textarea

.ffe-textarea {
    display: block;
    width: 100%;
    padding: @ffe-spacing-sm;
    font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
    border-radius: 4px;
    border: 2px solid @ffe-farge-graa-wcag;
    transition: all @ffe-transition-duration @ffe-ease;
    .ffe-fontsize-form-input();
    .native & {
        @media (prefers-color-scheme: dark) {
            background-color: @ffe-farge-svart;
            border-color: @ffe-farge-graa-wcag;
            box-shadow: none;
            color: @ffe-farge-hvit;
        }
    }

    &:hover {
        border-color: @ffe-farge-vann;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-vann-70;
            }
        }
    }

    &:focus,
    &:active {
        border-color: @ffe-farge-hvit;
        box-shadow: 0 0 0 2px @ffe-farge-vann;
        outline: none;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-svart;
                box-shadow: 0 0 0 2px @ffe-farge-vann-70;
            }
        }
    }
}
