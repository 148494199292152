// Input group
//
// Markup:
// <div class="ffe-input-group">
//     <label class="ffe-form-label" for="input-first-name">Fornavn</label>
//     <input class="ffe-input-field ffe-input-field{{modifier_class}}" id="input-first-name">
// </div>
//
// Styleguide ffe-form.input-group

.ffe-input-group {
    border: 0;
    position: relative;

    [aria-invalid='true'] {
        border-color: @ffe-farge-baer-wcag;
        border-style: solid;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-baer;
            }
        }

        &:focus {
            border-color: @ffe-farge-hvit;
            box-shadow: 0 0 0 2px @ffe-farge-baer-wcag;

            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-farge-svart;
                    box-shadow: 0 0 0 2px @ffe-farge-baer;
                }
            }
        }
    }

    .ffe-input-field--text-like {
        &[aria-invalid='true'] {
            border-bottom: 2px solid;
            border-color: @ffe-farge-baer-wcag;
            border-left-style: none;
            border-right-style: none;
            border-top-style: none;
            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-red-darkmode;
                }
            }
        }
    }

    > *:nth-last-child(1) {
        margin-bottom: @ffe-spacing-lg;
    }

    &--message {
        > *:nth-last-child(1) {
            margin-bottom: 0;
        }

        > *:nth-last-child(2) {
            margin-bottom: @ffe-spacing-lg;
        }
    }

    .ffe-field-error-message {
        margin-top: -24px;
        margin-bottom: 0;
    }

    &--no-extra-margin {
        > *:nth-last-child(1) {
            margin-bottom: auto;
        }

        > *:nth-last-child(2) {
            margin-bottom: auto;
        }

        .ffe-field-error-message {
            margin: @ffe-spacing-2xs 0 @ffe-spacing-xs;
        }
    }
}
