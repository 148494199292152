/* GAB: Use border-box: http://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
html {
    box-sizing: border-box;
    font-size: 100%; /* 16 px base in most browsers */
}

*,
*::before,
*::after {
    box-sizing: inherit;
}
