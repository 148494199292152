// Checkbox
//
// Markup:
// <span>
//     <input type="checkbox" class="ffe-hidden-checkbox" id="checkbox1" aria-invalid="false" name="newspapers" value="moon">
//     <label class="ffe-checkbox ffe-checkbox{{modifier_class}}" for="checkbox1">
//         Aftenposten
//     </label>
// </span>
// <span>
//     <input type="checkbox" class="ffe-hidden-checkbox" id="checkbox2" aria-invalid="false" name="newspapers" value="moon">
//     <label class="ffe-checkbox ffe-checkbox{{modifier_class}}" for="checkbox2">
//         VG
//     </label>
// </span>
//
// --inline    - Inline
//
// Styleguide ffe-form.checkbox

.ffe-checkbox {
    display: block;
    position: relative;
    margin: @ffe-spacing-xs 0;
    cursor: pointer;
    transition: width @ffe-transition-duration @ffe-ease;
    text-align: left;
    padding-left: @ffe-spacing-lg;
    -webkit-tap-highlight-color: fade(@ffe-farge-vann, 15%);
    .native & {
        @media (prefers-color-scheme: dark) {
            color: @ffe-farge-hvit;
        }
    }
    &--no-margin {
        margin-top: 0;
        margin-bottom: 0;
    }

    &--inline {
        display: inline-block;
        margin-right: @ffe-spacing-sm;
    }

    &::before {
        border: solid 2px @ffe-farge-graa-wcag;
        border-radius: 4px;
        content: '';
        display: inline-block;
        height: 20px;
        margin-right: @ffe-spacing-sm;
        position: absolute;
        top: 0;
        left: 0;
        transition: all @ffe-transition-duration @ffe-ease;
        width: 20px;
        background-color: @ffe-farge-hvit;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-graa;
                background-color: @ffe-farge-svart;
            }
        }
    }

    &--hidden-label {
        margin: 0;
        padding-left: 20px;
        padding-bottom: 20px;

        &::before {
            margin-right: 0;
        }
    }

    &::after {
        transform: scaleX(-1) rotate(180deg + -45deg);
        transform-origin: left top;
        border-right: 3px solid transparent;
        border-top: 3px solid transparent;
        content: '';
        display: block;
        height: 0;
        width: 6px;
        left: 4px;
        top: 11px;
        position: absolute;
        transition: height @ffe-transition-duration @ffe-ease;
    }
}

.ffe-hidden-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;

    &:checked + .ffe-checkbox::before,
    &:hover + .ffe-checkbox::before,
    &:focus + .ffe-checkbox::before,
    &:active + .ffe-checkbox::before {
        outline: none;
    }

    &:hover + .ffe-checkbox::before {
        border-color: @ffe-farge-vann;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-vann-70;
            }
        }
    }

    &:focus + .ffe-checkbox::before {
        box-shadow: 0 0 0 3px @ffe-farge-hvit, 0 0 0 5px @ffe-farge-vann;

        .native & {
            @media (prefers-color-scheme: dark) {
                box-shadow: 0 0 0 3px @ffe-farge-svart,
                    0 0 0 5px @ffe-farge-hvit;
            }
        }
    }

    &:focus:not(:focus-visible) + .ffe-checkbox::before {
        box-shadow: none;
    }

    &:checked + .ffe-checkbox::before {
        border-color: @ffe-farge-vann;
        background-color: @ffe-farge-hvit;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-vann-70;
                background-color: @ffe-farge-svart;
            }
        }
    }

    &:checked + .ffe-checkbox::after {
        border-color: @ffe-farge-vann;
        height: 11px;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-vann-30;
            }
        }
    }

    &[aria-invalid='true'] + .ffe-checkbox::before {
        border-color: @ffe-farge-baer;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-baer;
            }
        }
    }
}
