.ffe-icon-card {
    max-width: 440px;
    min-height: 130px;
    padding: @ffe-spacing-md;
    display: flex;
    flex-flow: row nowrap;

    &__icon {
        align-self: center;
        fill: @ffe-farge-fjell;
        min-width: 40px;
        width: 40px;
        height: 40px;
        margin: 0 @ffe-spacing-lg 0 @ffe-spacing-xs;
        .native & {
            @media (prefers-color-scheme: dark) {
                fill: @ffe-farge-vann-70;
            }
        }
    }

    &__body {
        overflow: hidden;
        text-align: left;
        width: 100%;
    }

    @media (min-width: @breakpoint-md) {
        .ffe-icon-card__icon {
            margin: 0 @ffe-spacing-md 0;
        }
    }

    @media (min-width: @breakpoint-lg) {
        .ffe-icon-card__icon {
            margin: 0 @ffe-spacing-lg 0 @ffe-spacing-xs;
        }
    }
}

.ffe-icon-card--condensed {
    min-height: auto;

    @media (min-width: @breakpoint-md) {
        min-height: 100px;
    }

    .ffe-icon-card__icon {
        min-width: 30px;
        width: 30px;
        height: 30px;
        margin: 0 @ffe-spacing-sm 0 0;
    }

    .ffe-icon-component--card-name {
        margin: 0 0 @ffe-spacing-2xs;
    }

    .ffe-icon-component--subtext {
        margin: 0;

        @media (min-width: @breakpoint-md) {
            margin: @ffe-spacing-2xs 0 0 0;
        }
    }
}

.ffe-icon-card--grey-charcoal {
    .ffe-icon-card__icon {
        fill: @ffe-farge-moerkgraa;
        .native & {
            @media (prefers-color-scheme: dark) {
                fill: @ffe-farge-graa;
            }
        }
    }

    .ffe-card-component--title,
    .ffe-icon-card__body {
        color: @ffe-farge-moerkgraa;
        .native & {
            @media (prefers-color-scheme: dark) {
                color: @ffe-farge-graa;
            }
        }
    }
}
