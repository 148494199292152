// Form label
//
// Markup:
// <label class="ffe-form-label ffe-form-label{{modifier_class}}">Label til skjemafelt</label>
//
// --block      - Block
//
// Styleguide ffe-form.form-label

.ffe-form-label {
    margin: 0;
    padding: @ffe-spacing-xs 0 @ffe-spacing-2xs;
    display: inline-block;
    position: relative;
    font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
    .ffe-fontsize-h6();

    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: @ffe-farge-fjell;
    .native & {
        @media (prefers-color-scheme: dark) {
            color: @ffe-farge-vann-70;
        }
    }

    &--block {
        display: block;
    }
}
