.ffe-card-component {
    &--card-name {
        margin: 0 0 @ffe-spacing-2xs 0;
    }

    &--text {
        margin: @ffe-spacing-2xs 0 0 0;
    }

    &--subtext {
        &:extend(.ffe-small-text);

        color: @ffe-farge-moerkgraa;
        .native & {
            @media (prefers-color-scheme: dark) {
                color: @ffe-farge-graa;
            }
        }

        margin: @ffe-spacing-2xs 0 0 0;
    }

    &--title {
        &:extend(.ffe-h5);
        .native & {
            @media (prefers-color-scheme: dark) {
                color: @ffe-farge-vann-70;
            }
        }

        margin: 0;

        &--overflow-ellipsis {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
