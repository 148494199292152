@import "ffe";

/* Mixins */
@import 'radioblob-mixin';

/* Inputs */
@import 'input-field';
@import 'textarea';
@import 'dropdown';
@import 'checkbox';
@import 'radio-input';
@import 'radio-button';
@import 'radio-switch';
@import 'radio-block';
@import 'phone-number';

/* Form Layout */
@import 'input-group';
@import 'form-label';
@import 'fieldset';
@import 'tooltip';

/* Validation */
@import 'field-info-message';
@import 'field-error-message';
@import 'field-success-message';

select,
textarea,
input:not([type='radio']):not([type='checkbox']) {
    appearance: none;
}
