// Radio switch
//
// Markup:
// <fieldset class="ffe-fieldset ffe-input-group">
//     <legend class="ffe-form-label">Vil bilen bli kjørt av sjåfører under 23 år?</legend>
//     <input type="radio" aria-invalid="false" class="ffe-radio-input" id="radio-switch-1"
//         value="true" name="under23">
//     <label for="radio-switch-1" class="ffe-radio-switch">Ja</label>
//     <input type="radio" aria-invalid="false" class="ffe-radio-input" id="radio-switch-2"
//         value="false" name="under23">
//     <label for="radio-switch-2" class="ffe-radio-switch">Nei</label>
// </fieldset>
//
// Styleguide ffe-form.radio-switch

.ffe-radio-switch {
    padding: 6px @ffe-spacing-sm 6px @ffe-spacing-2xl;
    position: relative;
    background-color: @ffe-farge-hvit;
    border: 2px solid @ffe-farge-graa-wcag;
    border-radius: 22px;
    display: inline-block;
    min-width: 100px;
    text-align: left;
    color: @ffe-farge-vann;
    font-family: 'SpareBank1-medium', 'MuseoSansRounded-700', arial, sans-serif;
    cursor: pointer;
    transition: all @ffe-transition-duration @ffe-ease;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05);
    margin-bottom: @ffe-spacing-2xs;
    margin-top: @ffe-spacing-sm;
    .native & {
        @media (prefers-color-scheme: dark) {
            background-color: @ffe-farge-svart;
            color: @ffe-farge-hvit;
            border-color: @ffe-farge-graa;
        }
    }

    &::before {
        .ffe-sb1-radioblob();
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-grey-cloud-darkmode;
                background-color: @ffe-grey-darkmode;
            }
        }

        left: 12px;
        top: 8px;
    }

    &.ffe-radio-button--invalid {
        border-color: @ffe-orange-fire;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-red-darkmode;
            }
        }
    }

    @media (min-width: @breakpoint-sm) {
        margin-bottom: 0;
    }

    & + input + .ffe-radio-switch {
        margin-left: @ffe-spacing-xs;
    }

    &--condensed {
        height: 38px;
    }
}

.ffe-radio-input {
    &:checked + .ffe-radio-switch {
        /* stylelint-disable-next-line selector-max-specificity */
        &:not(.ffe-radio-button--invalid) {
            border-color: @ffe-farge-fjell;
            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-farge-vann-70;
                }
            }
        }

        background-color: @ffe-farge-fjell;
        color: @ffe-white;
        .native & {
            @media (prefers-color-scheme: dark) {
                background-color: @ffe-farge-vann-70;
                color: @ffe-farge-svart;
            }
        }
    }

    &:hover + .ffe-radio-switch {
        border-color: @ffe-farge-fjell;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-vann-70;
            }
        }
    }

    &:focus + .ffe-radio-switch {
        box-shadow: 0 0 0 3px @ffe-farge-hvit, 0 0 0 5px @ffe-farge-vann;

        .native & {
            @media (prefers-color-scheme: dark) {
                box-shadow: 0 0 0 3px @ffe-farge-svart,
                    0 0 0 5px @ffe-farge-hvit;
            }
        }
    }

    /* stylelint-disable-next-line selector-max-specificity */
    &:checked + .ffe-radio-switch::before {
        border: 5px solid @ffe-farge-hvit;
        background: @ffe-farge-vann;
        .native & {
            @media (prefers-color-scheme: dark) {
                border-color: @ffe-farge-svart !important;
                background: @ffe-farge-vann-70 !important;
            }
        }
    }
}
