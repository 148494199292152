@import "ffe";

// Message box
//
// Markup:
// <div class="ffe-message-box">
//     <span class="ffe-message-box__icon ffe-message-box__icon{{modifier_class}}">
//         <svg focusable="false" viewBox="0 0 200 200" height="150" width="150" style="width: 40px; height: 40px;">
//             <path d="m96.19,199.99h7.846c7.1536,0,11.308-4.398,11.308-11.805v-102.99c0-7.407-4.1537-11.805-11.308-11.805h-7.846c-7.3844,0-11.769,4.3979-11.769,11.805v103c0.227,7.4054,4.38,11.805,11.765,11.805z"></path>
//             <path d="M116.5,11.805c0-7.4072-4.15-11.805-11.31-11.805h-10.382c-7.1577,0-11.308,4.3978-11.308,11.805v15.045c0,7.407,3.923,11.342,11.308,11.342h10.385c7.3844,0,11.308-3.935,11.308-11.342v-15.045z"></path>
//         </svg>
//     </span>
//     <div class="ffe-message-box__box ffe-message-box__box{{modifier_class}}">
//         <div class="ffe-h4 ffe-message-box__title{{modifier_class}}">Betalingen ble gjennomført</div>
//         <p class="ffe-body-paragraph">Nå er du helt gjeldsfri! Hurra!</p>
//     </div>
// </div>
//
// --tips      - Helpful tips
// --info      - Generic info
// --success   - Success message
// --error     - Error message
//
// Styleguide ffe-message-box

.ffe-message-box {
    text-align: center;
    .ffe-body-paragraph {
        .native & {
            @media (prefers-color-scheme: dark) {
                color: @ffe-farge-svart;
            }
        }
    }

    &__box {
        padding: @ffe-spacing-xl @ffe-spacing-lg;
        border-radius: 5px;
        color: @ffe-farge-svart;
        .native & {
            @media (prefers-color-scheme: dark) {
                color: @ffe-farge-svart;
            }
        }

        &--tips {
            background-color: @ffe-farge-sand-70;
            .native & {
                @media (prefers-color-scheme: dark) {
                    background-color: @ffe-farge-sand;
                }
            }
        }

        &--info {
            background-color: @ffe-farge-frost-30;
            .native & {
                @media (prefers-color-scheme: dark) {
                    background-color: @ffe-farge-vann-30;
                }
            }
        }

        &--success {
            background-color: @ffe-farge-nordlys-30;
        }

        &--error {
            background-color: @ffe-farge-baer-30;
        }
    }

    &__title {
        color: @ffe-farge-svart;
        font-size: 18px;
        margin-bottom: @ffe-spacing-sm;
        .native & {
            @media (prefers-color-scheme: dark) {
                color: @ffe-farge-svart;
            }
        }
    }

    &__list {
        padding-left: @ffe-spacing-sm;
        margin: 0;
        line-height: 2;
        text-align: left;
        .native {
            li {
                color: @ffe-farge-svart;
            }
        }
    }

    &__icon {
        background-color: @ffe-farge-hvit;
        border-radius: 50%;
        display: inline-flex;
        fill: @ffe-farge-fjell;
        height: 64px;
        margin-bottom: -16px;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 16px;
        width: 64px;
        .native & {
            @media (prefers-color-scheme: dark) {
                background-color: @ffe-farge-svart;
                fill: @ffe-farge-vann-70;
            }
        }
        &--error svg {
            fill: @ffe-farge-baer;
        }

        &--success svg {
            fill: @ffe-farge-nordlys;
        }
    }

    @media print {
        &__box {
            border: 2px dashed @ffe-farge-svart;
        }

        &__icon {
            border-bottom: 2px dashed @ffe-farge-svart;
            margin-bottom: -33.5px;
        }
    }

    &__link {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
            font-family: 'SpareBank1-medium', 'MuseoSansRounded-700', arial,
                sans-serif;
        }
    }
}
