// Tooltip
//
// Markup:
// <div class="ffe-tooltip ffe-tooltip{{modifier_class}}">
//   <button class="ffe-tooltip__icon" type="button">?</button>
//   <div class="ffe-tooltip__text" aria-expanded="true">
//     <div class="ffe-small-text">Dette er lurt å tenke på</div>
//   </div>
// </div>
//
// --open - Tooltip text visible
//
// Styleguide ffe-form.tooltip

.ffe-tooltip {
    @ffe-tooltip-size: 25px;

    &__icon {
        background: @ffe-farge-hvit;
        border-radius: 50%;
        border: 2px solid @ffe-farge-graa-wcag;
        color: @ffe-farge-vann;
        cursor: help;
        height: @ffe-tooltip-size;
        margin: 0 0 @ffe-spacing-2xs @ffe-spacing-sm;
        transition: border-color @ffe-transition-duration @ffe-ease;
        width: @ffe-tooltip-size;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-family: sans-serif;
        .ffe-fontsize-button();
        .native & {
            @media (prefers-color-scheme: dark) {
                background: @ffe-farge-svart;
                color: @ffe-farge-vann-70;
                border-color: @ffe-farge-graa;
            }
        }

        > span {
            margin-top: 2px;
        }

        &:hover {
            border-color: @ffe-farge-vann;
            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-farge-vann-70;
                    color: @ffe-farge-vann-70;
                }
            }
        }

        &:focus {
            box-shadow: 0 0 0 3px @ffe-farge-hvit, 0 0 0 5px @ffe-farge-vann;
            outline: none;

            .native & {
                @media (prefers-color-scheme: dark) {
                    box-shadow: 0 0 0 3px @ffe-farge-svart,
                        0 0 0 5px @ffe-farge-hvit;
                }
            }
        }

        &:active,
        .ffe-tooltip--open & {
            border-color: @ffe-farge-vann;
            color: @ffe-farge-fjell;
            .native & {
                @media (prefers-color-scheme: dark) {
                    border-color: @ffe-farge-vann-70;
                    color: @ffe-farge-vann-70;
                }
            }
        }
    }

    &__text {
        will-change: height;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        transition: height @ffe-transition-duration @ffe-ease;
    }

    &--open &__text {
        padding-bottom: @ffe-spacing-sm;
        height: auto;
        overflow: visible;
        visibility: visible;
    }
}
