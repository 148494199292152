.st0 {
    fill: #009FE3;
}

.st1 {
    fill: #A6DDF5;
}

.st2 {
    fill: #012A4C;
}

.st3 {
    fill: #FFFFFF;
}

.dfo-container {
    margin: 0 20px;
}

.dfo-footer {
    background-color: #043b90;
    padding: 6rem 0;
    color: #fff;
}

.dfo-footer--compact {
    padding: 4rem 0;
}

@media screen and (max-width: 640px) {
    .dfo-footer__contact-wrapper {
        margin-top: 4rem;
    }
}

.dfo-footer__contact {
    list-style-type: none;
    color: #fff;
    padding-left: 0;
    font-size: 1.8rem;
    margin: 0;
}

.dfo-footer__contact a {
    color: #fff;
}

.dfo-footer__logo {
    margin-bottom: 2rem;
}

.dfo-nav {
    margin-bottom: 4rem;
    clear: both;
}

.dfo-nav--nomargin {
    margin-bottom: 0;
}

.dfo-nav--light {
    color: #fff;
}

.dfo-nav--light .dfo-nav__item a {
    color: #fff;
}

.dfo-nav--light .dfo-nav__item a:hover {
    color: #009EE1;
}

.dfo-nav__links {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
}

.dfo-nav__links--no-margin {
    margin: 0;
}

.dfo-nav__link {
    padding: 1rem 8.2rem 1rem 2rem;
    text-decoration: none;
    color: #333;
    font-size: 1.8rem;
    display: block;
    position: relative;
}

.dfo-nav__link:hover {
    color: #009EE1;
}

.dfo-nav__link__symbol {
    position: absolute;
    top: 1.3rem;
    right: 2rem;
}

.dfo-nav__item {
    border-bottom: 1px solid #009EE1;
    font-weight: normal;
    cursor: pointer;
}

.dfo-nav__item:first-child {
    border-top: 1px solid #009EE1;
}

.dfo-nav__item:nth-last-child(1) {
    border-bottom: 1px solid #009EE1;
}