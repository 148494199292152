.ffe-text-card {
    padding: @ffe-spacing-sm @ffe-spacing-lg;
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    overflow: hidden;
    min-height: auto;

    @media (min-width: @breakpoint-md) {
        min-height: 100px;
    }
}

.ffe-text-card--left-align {
    text-align: left;
}
