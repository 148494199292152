@import "ffe";

.ffe-details-list-react__content {
    @media (min-width: @breakpoint-md) {
        padding-bottom: 15px;
        padding-top: 15px;
    }

    &--action {
        align-items: flex-start;

        @media (min-width: @breakpoint-md) {
            align-items: flex-end;
        }
    }
}
